import { useState } from "react";
import "./App.css";
import EmailChallengerFour from "./components/ChallengeFour/EmailChallengeFour";
import EmailChallengerOne from "./components/ChallengeOne/EmailChallengerOne";
import EmailChallengerThree from "./components/ChallengeThree/EmailChallengeThree";
import EmailChallengerTwo from "./components/ChallengeTwo/EmailChallengeTwo";
import Confirmed from "./components/Confirmed/Confirmed";
import DeviceFingerprint from "node_js_ipqs_device_tracker";
import ErrorPage from "./components/ErrorPage";
import LanguageButton from "./components/LanguageButton";

// import custom hooks
import { useZipCode } from "./hooks/useZipCode";

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get(`token`);

  const errorMessages = {
    tokenError: "You provided an invalid URL. Please try again.",
    dataLoad: "There was an issue fetching your information. Please try again.",
    acceptTerms:
      "There was an issue accepting terms & conditions. Please try again.",
    submitFailed: "We were unable to submit your data. Please try again.",
    sessionExpired:
      "The process has already been completed. If you have not completed the verification process, please contact help@ideveryone.com.",
  };

  const [pageErrorMessage, setPageErrorMessage] = useState(
    "The was a problem. Please try again."
  );
  const [currentPage, setCurrentPage] = useState(1);

  // Challenger questions
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useZipCode();

  // Loaded data
  const [terms, setTerms] = useState("Loading terms & conditions...");
  const [recipientName, setRecipientName] = useState("");
  const [senderFirstName, setSenderFirstName] = useState("");
  const [senderLastName, setSenderLastName] = useState("");

  const [challengerButtonsScript, setChallengerButtonsScript] = useState({});
  const [challengeOneScript, setChallengeOneScript] = useState({});
  const [challengeTwoScript, setChallengeTwoScript] = useState({});
  const [challengeThreeScript, setChallengeThreeScript] = useState({});
  const [challengeFourScript, setChallengeFourScript] = useState({});
  const [challengeCompleteScript, setChallengeCompleteScript] = useState({});

  const supportedLanguages = ["en_US", "fr_CA"];

  // If the users language is set to an unsupported language, default to english
  const [language, setLanguage] = useState(() => {
    const currentLanguage = navigator.language.replace("-", "_");

    if (supportedLanguages.includes(currentLanguage)) return currentLanguage;
    else return supportedLanguages[0];
  });

  function setLanguageData(data) {
    setChallengerButtonsScript({
      step: data.step,
      of: data.of,
      accept: data["nav_accept"],
      confirm: data["nav_confirm"],
      previousStep: data["nav_previous_step"],
    });

    setChallengeOneScript({
      introMessage: data["intro_message"],
      senderMessage: data["sender_message"],
      minuteMessage: data["minute_message"],
      termsHeader: data["terms_header"],
      termsLabel: data["terms_label"],
      yourAgreement: data["your_agreement"],
      navNext: data["nav_next"],
      terms: data["termsAndConditions"],
    });
    setChallengeTwoScript({
      nameHeader: data["name_header"],
      firstName: data["first_name"],
      lastName: data["last_name"],
    });
    setChallengeThreeScript({
      zipCodeHeaderOne: data["zip_code_header_1"],
      zipCodeHeaderTwo: data["zip_code_header_2"],
      zipCode: data["zip_code"],
    });
    setChallengeFourScript({
      permissionHeader: data["permission_header"],
      permissionHeaderRequest: data["permission_header_request"],
      permissionYes: data["permission_yes"],
      permissionNo: data["permission_no"],
    });
    setChallengeCompleteScript({
      summaryLabel: data["summary_label"],
      summarySteps: data["summary_steps"],
      summarySenderMessage: data["summary_sender_message"],
      summaryContactUs: data["summary_contactus"],
    });
  }

  /**
   * START IPQS Tracker
   *
   * Reference: https://www.npmjs.com/package/node_js_ipqs_device_tracker
   * @type {string}
   */
  // TODO: Need to add a config like we did in the Portal to load this using process.env.SECRET_KEY
  // Use the package.json file to set environment variables, look at the portal
  const secretKey = process.env.REACT_APP_IPQS_SECRET_KEY;

  async function updateLanguageSettings(language) {
    async function getDeviceFingerprint(response) {
      DeviceFingerprint.initializeScriptAsync(secretKey)
        .then(async () => {
          DeviceFingerprint.AfterResult((result) => {
            console.log("Result", result);
          });
          DeviceFingerprint.Init();

          DeviceFingerprint.Store("userID", response?.data?.scanId);
        })
        .catch((err) => {
          console.log("DT Error");
          console.log(err);
        });
    }

    try {
      const url = `${process.env.REACT_APP_API_ROOT_URL}/challenger/verification?token=${token}&lang=${language}`;

      const response = await fetch(url, {
        method: "GET",
      });

      if (response.status === 401) {
        setPageErrorMessage(errorMessages.sessionExpired);
        setCurrentPage(-1);
        return;
      } else if (response.status === 200) {
        setCurrentPage(2);
      }

      const jsonResponse = await response.json();

      if (jsonResponse?.data) {
        setLanguageData(jsonResponse.data.translationStrings);

        const tempTerms = jsonResponse.data.termsAndConditions;
        const tempRecipientName = jsonResponse.data.recipientName;
        const tempSenderFirstName = jsonResponse.data.senderFirstName;
        const tempSenderLastName = jsonResponse.data.senderLastName;

        loadDataToStates(
          tempTerms,
          tempRecipientName,
          tempSenderFirstName,
          tempSenderLastName
        );

        await getDeviceFingerprint(jsonResponse);
      }
    } catch (err) {
      setPageErrorMessage(`${errorMessages.tokenError}`);
      setCurrentPage(-1);
    }
  }

  // useEffect(() => {

  // }, []);
  /**
   * END IPQS Tracker
   */

  function loadDataToStates(
    newTerms,
    newRecipientName,
    newSenderFirstName,
    newSenderLastName
  ) {
    setTerms(newTerms);
    setRecipientName(newRecipientName);
    setSenderFirstName(newSenderFirstName);
    setSenderLastName(newSenderLastName);

    if (currentPage !== 0) return;
    setCurrentPage(1);
  }

  async function submitData() {
    function getPostData() {
      const newObject = {
        firstName: firstName,
        lastName: lastName,
        zipCode: zipCode,
        token: token,
      };

      return newObject;
    }

    const url = `${process.env.REACT_APP_API_ROOT_URL}/challenger/verification`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(getPostData()),
    });

    if (response.status !== 200) {
      setPageErrorMessage(errorMessages.submitFailed);
      setCurrentPage(-1);
    } else {
      setCurrentPage(6);
    }
  }

  return (
    <div
      className="content"
      style={{
        margin: "100px 0px 100px 0px",
      }}
    >
      {currentPage === -1 && <ErrorPage message={pageErrorMessage} />}
      {currentPage === 1 && (
        <LanguageButton
          language={language}
          setLanguage={setLanguage}
          updateLanguageSettings={updateLanguageSettings}
          challengerButtonsScript={challengerButtonsScript}
          setCurrentPage={setCurrentPage}
        />
      )}
      {currentPage === 2 && (
        <EmailChallengerOne
          props={{
            acceptedTerms,
            setAcceptedTerms,
            setCurrentPage,
            terms,
            token,
            challengerButtonsScript,
            challengeOneScript,
          }}
        />
      )}
      {currentPage === 3 && (
        <EmailChallengerTwo
          props={{
            firstName,
            lastName,
            setFirstName,
            setLastName,
            setCurrentPage,
            challengerButtonsScript,
            challengeTwoScript,
          }}
        />
      )}
      {currentPage === 4 && (
        <EmailChallengerThree
          props={{
            zipCode,
            setZipCode,
            setCurrentPage,
            challengerButtonsScript,
            challengeThreeScript,
          }}
        />
      )}
      {currentPage === 5 && (
        <EmailChallengerFour
          props={{
            submitData,
            setCurrentPage,
            challengerButtonsScript,
            challengeFourScript,
          }}
        />
      )}
      {currentPage === 6 && (
        <Confirmed
          senderFirstName={senderFirstName}
          challengerButtonsScript={challengerButtonsScript}
          challengeCompleteScript={challengeCompleteScript}
        />
      )}
    </div>
  );
}

export default App;
