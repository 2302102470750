import { useState, useEffect, useCallback } from "react";

export function useZipCode() {
  const [zipCode, setZipCode] = useState("");

  const getZipCode = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT_URL}/challenger/verification/postalcode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            latitude: latitude,
            longitude: longitude,
          }),
        }
      );

      const data = await response.json();

      if (data && data.data.postalCode) {
        return data.data.postalCode;
      }

      return null;
    } catch (error) {
      console.error("Error fetching zip code", error);
      return null;
    }
  };

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      console.log("Geolocation is supported!");
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const zip = await getZipCode(latitude, longitude);
        console.log("Identified zip:", zip);
        if (zip) {
          setZipCode(zip);
        }
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  return [zipCode, setZipCode];
}
